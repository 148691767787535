<template>
    <div class="Site PageBox">
        <van-nav-bar
                fixed
                :border="false"
                :title="$t('common2[1]')"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <!--    <van-image width="100%" fit="contain" src="./static/images/customer.png" />-->
        <div class="ScrollBox" style="height: 600px">
            <van-button color="#252D30" v-if="service_url2.length>0"
                        size="large" style="margin: 10px;width: 95%;height: 85px;display: flex" @click="gotoUrl">
                <van-grid :border="false" :column-num="1" icon-size="50" gutter="5" direction="horizontal">
                    <van-icon name="./static/icon/customer.png" size="45"/>
                    <div style="margin-top: 15px;margin-left: 20px">
                        {{$t('common2[3]')}}
                    </div>
                </van-grid>
            </van-button>
          <van-button color="#252D30" v-if="InitData.setting.service_url.length>0"
                      size="large" style="margin: 10px;width: 95%;height: 85px;display: flex" @click="doShow">
            <van-grid :border="false" :column-num="1" icon-size="50" gutter="5" direction="horizontal">
              <van-icon name="./static/icon/customer1.png" size="45"/>
               <div style="margin-top: 15px;margin-left: 20px"> {{$t('common2[4]')}}</div>
            </van-grid>
          </van-button>


            <div v-show="showAddress" class="showAdd">
                <div style="display: flex">
                    <div>
                        <van-button style="margin-right: 10px;height: 30px" size="normal" color="#13C465" @click="copyCode"
                                    class="copy-qb">{{ $t('invite[3]') }}</van-button>
                        <!--          <van-button size="normal" color="#006699" @click="openUrl">{{ $t('common5[4]') }}</van-button>-->
                    </div>
                    <div style="margin: 20px">{{InitData.setting.service_url}}</div>
                </div>
                <img :src="service_hotline" style="width: 150px;height: 150px"/>
                <div style="height: 40px;"/>
            </div>

            <div v-show="showAddress2" class="showAdd">
                <div style="display: flex">
                    <div style="margin: 20px">{{InitData.setting.service_url2}}</div>
                    <div>
                        <van-button style="margin-right: 10px;height: 30px" size="normal" color="#13C465" @click="copyCode2"
                                    class="copy-qb2">{{ $t('invite[3]') }}</van-button>
                        <!--          <van-button size="normal" color="#006699" @click="openUrl">{{ $t('common5[4]') }}</van-button>-->
                    </div>
                </div>
                <img class="QRCode" id="picurl2" :src="service_hotline2" />
                <div style="height: 40px;"/>
            </div>
        </div>
        <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
    </div>
</template>
<script>
(function(a, b, c, d, e, j, s) {
    a[d] = a[d] || function() {
        (a[d].a = a[d].a || []).push(arguments)
    };
    j = b.createElement(c),
        s = b.getElementsByTagName(c)[0];
    j.async = true;
    j.charset = 'UTF-8';
    j.src = 'https://static.meiqia.com/widget/loader.js';
    s.parentNode.insertBefore(j, s);
})(window, document, 'script', '_MEIQIA');
_MEIQIA('entId', '6dd521eafcae7ffcbcc68de91ac68547');
_MEIQIA('language','en')
    console.log("ddd");
    import Clipboard from "clipboard";

    export default {
        name: 'Service',
        components: {

        },
        props: {},
        data() {
            return {
                showAddress:false,
                showAddress2:false,
                service_url2:'',
                service_hotline:'',
                service_hotline2:'',
            }
        },
        computed: {
        },
        watch: {

        },
        created() {

        },
        mounted() {
            this.service_hotline = this.InitData.setting['seal_img'];
            debugger
            this.service_hotline2 = this.InitData.setting['service_hotline2'];
            this.service_url2 = this.InitData.setting.service_url2;
        },
        activated() {

        },
        destroyed() {

        },
        methods: {
            copyCode() {
                var that = this;
                var copyValue = this.InitData.setting.service_url;
                let clipboard = new Clipboard('.copy-qb', {
                    text: () => {
                        return copyValue
                    }
                })
                clipboard.on('success', () => {
                    that.$Dialog.Toast(this.$t('recharge.label[5]'));
                    clipboard.destroy()
                })
                clipboard.on('error', () => {
                    that.$Dialog.Toast(this.$t('taskOrder2[3]'));
                    clipboard.destroy()
                })
            },
            copyCode2() {
                var that = this;
                var copyValue = this.InitData.setting.service_url2;
                let clipboard = new Clipboard('.copy-qb2', {
                    text: () => {
                        return copyValue
                    }
                })
                clipboard.on('success', () => {
                    that.$Dialog.Toast(this.$t('recharge.label[5]'));
                    clipboard.destroy()
                })
                clipboard.on('error', () => {
                    that.$Dialog.Toast(this.$t('taskOrder2[3]'));
                    clipboard.destroy()
                })
            },
            openUrl(){
                window.location.href = this.InitData.setting.service_url;
            },
            doShow(){
                this.showAddress = !this.showAddress;
                this.showAddress2 = false;
            },
            gotoUrl(){
                window.location.href = this.service_url2
            },
            doShow2(){
                // this.showAddress2 = !this.showAddress2;
                this.showAddress = false;
                // this.$router.push("/innerpage");
                _MEIQIA('showPanel');
                // var service_url2 = "https://chatlink-new.meiqia.cn/widget/standalone.html?eid=d9b85f92369ddfd0e081d79dab3fb2de";
                // window.location.href = this.InitData.setting.service_url2
            },
            savePicture(){
                var Url= this.service_hotline;
                var blob=new Blob([''], {type:'application/octet-stream'});
                var url = URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = Url;
                a.download = Url.replace(/(.*\/)*([^.]+.*)/ig,"$2").split("?")[0];
                var e = document.createEvent('MouseEvents');
                e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
                URL.revokeObjectURL(url);
            },
            saveImg(){
                var fileName = "service1code.png"
                let canvas = this.qrcode._el.querySelector("canvas");//获取生成二维码中的canvas，并将canvas转换成base64
                var base64Text = canvas.toDataURL("image/png");
                var blob = this.getBlob(base64Text);
                if (navigator.msSaveBlob) {
                    // IE的Blob保存方法
                    navigator.msSaveBlob(blob, fileName);
                } else {
                    var link = document.createElement('a');
                    var href = window.URL.createObjectURL(blob);
                    link.href = href;
                    link.download = fileName;  //a标签的下载属性
                    document.body.appendChild(link); // firefox需要把a添加到dom才能正确执行click
                    link.click();
                    // 延时保证下载成功执行，否则可能下载未找到文件的问题
                    setTimeout(function () {
                        window.URL.revokeObjectURL(href); // 释放Url对象
                        document.body.removeChild(link);
                    }, 100);
                }

            },
            getBlob(base64) {
                var mimeString = base64.split(',')[0].split(':')[1].split(';')[0]; // mime类型
                var byteString = atob(base64.split(',')[1]); //base64 解码
                var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
                var intArray = new Uint8Array(arrayBuffer); //创建视图
                for (var i = 0; i < byteString.length; i += 1) {
                    intArray[i] = byteString.charCodeAt(i);
                }
                return new Blob([intArray], {
                    type: mimeString
                });
            },
        }
    }
</script>
<style scoped>
    .PageBox {
        padding-top: 40px;
        background-color:#121617;
        color: #aaaaaa;
    }
    .PageBox>>>.van-nav-bar{
        background: #252D30;
    }
    .van-nav-bar>>>.van-nav-bar__title{
        color:#fff;
    }
    .ScrollBox{

    }

    .showAdd {
        background: #252D30;
        border-radius: 10px;
        color: #d7d7d7;
        margin: auto;width: 95%;padding: 20px;text-align: center;

    }
    .van-grid-item__content {
        padding: 10px !important;
        display: flex;
        color: #D7D7D7;
        background-color:#252D30;
    }

    .van-cell {
        margin-top: 5px;
        color: #aaaaaa;
        background-color: #252D30;
    }
    .QRCode {
        margin-left: 10px;
        height: 230px;
    }

</style>